// reset
html {
  background-color: @body-bg;  
}
body {
  font-family: @font-family-base;
  font-size: @font-size-base;
  color: @text-color;
  -webkit-font-smoothing: antialiased;
  line-height: @line-height-base;
}

*:focus {
  outline: 0 !important;
}

.h1, .h2, .h3, .h4, .h5, .h6{
  margin: 0;
}

// Links
// -------------------------

a {
  color: @link-color;
  text-decoration: none;
  cursor: pointer;
}
a:hover,
a:focus {
  color: @link-hover-color;
  text-decoration: none;
}

label{font-weight: normal;}
small, .small{font-size: @font-size-sm;}
.badge, .label{font-weight: bold; text-shadow: 0 1px 0 rgba(0, 0, 0, .2)}
.badge.bg-light, .label.bg-light{text-shadow:none;}
.badge{
  background-color: @badge-bg;
  &.up{
    position: relative;
    top: -10px;
    padding:3px 6px;
    margin-left: -10px;
  }
}

.badge-sm{
  font-size: 85%;
  padding: 2px 5px !important;
}

.label-sm{
  padding-top: 0;
  padding-bottom: 1px;
}

.badge-white {
  background-color: transparent;
  border: 1px solid rgba(255,255,255,0.35);
  padding: 2px 6px;
}

.badge-empty {
  background-color: transparent;
  border: 1px solid rgba(0,0,0,0.15);
  color: inherit;
}

blockquote{
  border-color: @border-color;
}

.caret-white{
  border-top-color: #fff;
  border-top-color: rgba(255,255,255,.65);
  a:hover & {
    border-top-color: #fff;
  }
}

.thumbnail{
  border-color: @border-color;
}

.progress{
  background-color: @brand-light;
}
.progress-xxs{
  height: 2px
}
.progress-xs{
  height: 6px
}
.progress-sm{
  height: 12px;
  .progress-bar{
    font-size: 10px;
    line-height: 1em;
  }
}

.progress, .progress-bar{
  .box-shadow(none);
}

.progress-bar-primary{
  background-color: @brand-primary;
}
.progress-bar-info{
  background-color: @brand-info;
}
.progress-bar-success{
  background-color: @brand-success;
}
.progress-bar-warning{
  background-color: @brand-warning;
}
.progress-bar-danger{
  background-color: @brand-danger;
}
.progress-bar-black{
  background-color: @brand-black;
}
.progress-bar-white{
  background-color: #fff;
}


.accordion-group,
.accordion-inner{
  border-color: @border-color;
  border-radius: @border-radius-base;
}

.alert{
  font-size: @font-size-sm;
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.2);
  .close i{
    font-size: 12px;
    font-weight: normal;
    display: block;
  }
}

.easy-autocomplete-container {
  color: @brand-dark;
}

.form-control{
  border-color: @input-border;
  border-radius: @input-border-radius;
  background-color: @brand-white;
  font-size: 16px;
  color: @brand-dark;
  padding-top: 8px;
  padding-bottom: 8px;
  height: auto;
  &,
  &:focus {    

  }
  &:focus{
    border-color: @input-border-focus;
  }
}


.form-horizontal{
   .control-label.text-left{
      text-align: left;
   }
}


.form-control-spin{
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 50%;
  margin-top: -7px;
}

.input-group-addon{
  border-color: @input-border;
  background-color: @brand-light;
}

.list-group{
  border-radius: @border-radius-base;  
  &.no-radius {
    .list-group-item{
      border-radius: 0 !important;
    }
  }
  &.no-borders {
    .list-group-item{
      border: none;
    }
  }
  &.no-border{
    .list-group-item{
      border-width: 1px 0;
    }
  }
  &.no-bg{
    .list-group-item{
      background-color: transparent;
    }
  }
}

.list-group-item{
  border-color: @list-group-item-border;
  padding-right: 15px;
  background-color: @list-group-item-background;
  color: @list-group-item-color;
  a&{
    &:hover,
    &:focus,
    &.hover{
      background-color: @list-group-item-hover;
    }
  }
  &.media {
    margin-top: 0;
  }
  &.active {
    color: #fff;
    border-color: @list-group-active-color !important;
    background-color: @list-group-active-color !important;
    .text-muted{
      color: lighten(@list-group-active-color, 30%) !important;
    }
    a{
      color: #fff;
    }
  }
  &.focus{
    background-color: @list-group-item-focus !important;
  }
  &.select{
    position: relative;
    z-index: 1;
    background-color: @list-group-select-color !important;
    border-color: darken( @list-group-select-color , 5%);
  }
  
  .list-group-alt & {
    &:nth-child(2n+2){
      background-color: rgba(0,0,0,0.02) !important;
    }
  }
  .list-group-lg & {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .list-group-sm & {
    padding: 6px 10px;
  }
  .list-group-sp & {
    margin-bottom: 5px;
    border-radius: 3px;
  }
  > .badge{
    margin-right: 0;
  }
  > .fa-chevron-right {
    float: right;
    margin-top: 4px;
    margin-right: -5px;
    & + .badge{
      margin-right: 5px;
    }
  }
}

.nav-pills{
  &.no-radius {
    > li{
      > a {
        border-radius: 0;
      }
    }
  }
  > li{
    &.active {
      > a{
        color: #fff !important;
        background-color: @brand-info;
        &:hover,
        &:active{
          background-color: darken(@brand-info, 5%);
        }
      }
    }
  }
}

.nav{
  > li{
    > a{
      &:hover,
      &:focus{
        background-color: rgba(0,0,0,0.05);
      }
    }
  }
  &.nav-lg{
    > li > a{
      padding: 20px 20px;
    }
  }
  &.nav-md{
    > li > a{
      padding: 15px 15px;
    }
  }
  &.nav-sm{
    > li > a{
      padding: 6px 12px;
    }
  }
  &.nav-xs{
    > li > a{
      padding: 4px 10px;
    }
  }
  &.nav-xxs{
    > li > a{
      padding: 1px 10px;
    }
  }
  &.nav-rounded{
    > li > a{
      border-radius: 20px;
    }
  }
  .open{
    > a{
      &,
      &:hover,
      &:focus{
        background-color: rgba(0,0,0,0.05);
      }
    }
  }
}

.nav-tabs{
  border-color: @border-color;
  > li{
    > a{
      border-radius: @panel-border-radius @panel-border-radius 0 0;      
      border-bottom-color: @border-color;
    }
    &:hover > a,
    &.active > a,
    &.active > a:hover{
      border-color: @border-color;      
    }
    &.active > a{
      border-bottom-color: #fff !important;
    }
  }
  .nav-tabs-alt & {
    &.nav-justified{
      > li{
        display: table-cell;
        width: 1%;
      }
    }
    > li{
      > a {
        border-radius: 0;
        border-color: transparent !important;
        background: transparent !important;
        border-bottom-color: @border-color !important;
      }
      &.active{
        > a {
          border-bottom-color: @brand-info !important;          
        }
      }      
    }
  }
}

.tab-container{
  margin-bottom: 15px;
  .tab-content{
    padding:15px;
    background-color: #fff;
    border: 1px solid @border-color;
    border-top-width: 0; 
    border-radius: 0 0 @panel-border-radius @panel-border-radius;
  }
}

.pagination{
  > li{
    > a{
      border-color: @border-color;
      &:hover,
      &:focus{
        border-color: @border-color;
        background-color: @brand-light;
      }
    }
  }
}

.panel{
  border-radius: @panel-border-radius;
  .accordion-toggle{
    font-size: 14px;
    display: block;
    cursor: pointer;
  }
  .list-group-item{
    border-color: @panel-list-group-border;
  }
  &.no-borders{
    border-width: 0;
    .panel-heading,
    .panel-footer{
      border-width: 0;
    }
  }
}

.panel-heading{
  .panel-default &{
    background-color: @panel-heading-bg;
  }
  border-radius: @panel-border-radius @panel-border-radius 0 0;
  &.no-border{
    margin:-1px -1px 0 -1px;
    border: none;
  }
  .nav{
    margin: -10px  -15px;
  }
  .list-group{
    background: transparent;
  }
}

.panel-footer{
  border-color: @panel-heading-border;
  border-radius: 0 0 @panel-border-radius @panel-border-radius;
  background-color: @panel-footer-bg;
}

.panel-default{
  border-color: @panel-border;
  > .panel-heading,
  > .panel-footer {
    border-color: @panel-heading-border;
  }
}

.panel-group .panel-heading + .panel-collapse .panel-body{
  border-top: 1px solid #eaedef;
}

.table{
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding:8px 15px;
        border-top: 1px solid @table-border-color;
      }
    }
  }
  > thead > tr > th{
    padding:8px 15px;
    border-bottom: 1px solid @table-border-color;
  }
}
.table-bordered{
  border-color: @table-border-color;
  > tbody{
    > tr{
      > td{
        border-color: @table-border-color;
      }
    }
  }
  > thead > tr > th{
    border-color: @table-border-color;
  }
}
.table-striped{
  > tbody {
    > tr{
      &:nth-child(odd){
        > td,
        > th{
          background-color: @table-striped-color;
        }
      }
    }
  }
  > thead {
    > th{
      background-color: @table-striped-color;
      border-right: 1px solid @table-border-color;
      &:last-child{
        border-right: none
      }
    }
  }
}

.well, pre {
  background-color: @brand-light;
  border-color: @border-color;
}

.dropdown-menu{
  border-radius: @border-radius-base;
  .box-shadow(0 2px 6px rgba(0, 0, 0, 0.1));
  border: 1px solid @border-color;
  border: 1px solid rgba(0, 0, 0, 0.1);
  &.pull-left{
    left:100%;
  }
  > .panel{
    border: none;
    margin: -5px 0;
  }
  > li > a{
    padding: 5px 15px;
  }
  > li > a:hover,
  > li > a:focus,
  > .active > a,
  > .active > a:hover, 
  > .active > a:focus{
    background-image: none;
    filter:none;
    background-color: @brand-light !important;
    color: @text-color;
  }
}

.dropdown-header{
  padding: 5px 15px;
}

.dropdown-submenu{
  position: relative;
  &:hover,
  &:focus{
    > a{
      background-color: @brand-light !important;
      color: @text-color;
    }    
    > .dropdown-menu {
      display: block;
    }
  }
  &.pull-left{
    float: none !important;
    > .dropdown-menu{
      left: -100%;
      margin-left: 10px;
    }
  }
  .dropdown-menu{
    left:100%;
    top:0;
    margin-top: -6px;
    margin-left: -1px
  }

  .dropup & {
    > .dropdown-menu{
      top: auto;
      bottom: 0;
    }
  }
}

.btn-group > .btn{
  margin-left: -1px;
}

/*cols*/
.col-lg-2-4 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col-0{clear:left;}

.row.no-gutter{
  margin-left: 0;
  margin-right: 0;
}

.no-gutter [class*="col"]{
  padding: 0;
}

.row-sm{
  margin-left: -10px;
  margin-right: -10px;
  > div{
    padding-left: 10px;
    padding-right: 10px;
  }
}

.modal-backdrop{
  background-color: @brand-dark;
  &.in{
    opacity: 0.8;
    filter: alpha(opacity=80);
  }
}

.modal-over{
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
}

.modal-center{
  position: absolute;
  left:50%;
  top:50%;
}

.has-error .form-control {
  border-width: 3px;

}