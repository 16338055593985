// Core variables and mixins
@import "app.variables.less";
@import "app.mixins.less";
@import "app.reset.less";
@import "app.layout.less";
@import "app.layout.boxed.less";
@import "app.nav.less";
@import "app.nav.offscreen.less";
@import "app.nav.dock.less";

@import "app.arrow.less";
@import "app.buttons.less";
@import "app.widgets.less";

@import "app.components.less";
@import "app.plugin.less";
@import "app.item.less";
@import "app.ng.less";

@import "app.colors.less";
@import "app.utilities.less";

@import "app.butterbar.less";

.floatingWidget {
  display: none;
  z-index: 1050;

  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.floatingWidget.active {
  display: block;
  position: absolute;
  right: 220px;
  min-width: 300px;
}

.cursor-pointer {
  cursor: pointer;
}

.table-highlight-dark,
.table-highlight-dark td {
  color: #a6a8b1 !important;
  background-color: #232735 !important;
}

.table-highlight-light,
.table-highlight-light td {
  color: #232735 !important;
  background-color: #dcf2f8 !important;
}

.table-highlight-warning,
.table-highlight-warning td {
  color: #232735 !important;
  background-color: #fad733 !important;
}

.table-border-marker {
  border-left: 3px solid #27c14c !important;
}

.table-border-end-marker {
  border-right: 3px solid #ed3939 !important;
}

.table-min th, .table-min td {
  padding: 2px !important;
  font-size: 14px !important;
}

.slick-next {
  background-color: #232735 !important;
}

.slick-prev {
  background-color: #232735 !important;
}

.img-embed-container {
  position: relative;
  display: inline-block;
}

.img-button-embed {
  position: absolute;
  opacity: 0.4;
  -moz-opacity: 0.4;
  top: 10px;
  right: 10px;
}

.embed-pos-left {
  top: 10px;
  left: 10px !important;
}

.img-button-embed:hover {
  opacity: 1.0;
  -moz-opacity: 1;
}